import { Controller } from "@hotwired/stimulus";
import {
  configureChains,
  createConfig,
  connect,
  getWalletClient,
  watchNetwork,
  switchNetwork,
  watchWalletClient,
  watchAccount,
} from "@wagmi/core";
import { InjectedConnector } from "@wagmi/core/connectors/injected";
import { polygonMumbai } from "@wagmi/core/chains";
import { publicProvider } from "@wagmi/core/providers/public";
import Pusher from "pusher-js/with-encryption";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;

export default class extends Controller {
  static targets = ["button"];
  static values = {
    company: String,
    subscription: String,
    subscriptionid: Number,
    token: String,
    to: String,
    amount: Number,
    numberofpayments: String,
    durationindays: Number,
  };

  initialize() {
    //track out connected state
    this.walletClient = null;
    this.walletConnected = false;
    this.subscriptionState = 0; //did nothing
    this.signedSub = false;
    this.permitSigned = false;
    this.processedNonce = false;
    this.nonce = 0;
    this.walletChannel = null;
    this.address = null;
    this.pusher = new Pusher("38cdfdab9daf52f5c1d1", {
      cluster: "us2",
    });

    const { publicClient, webSocketPublicClient } = configureChains(
      [polygonMumbai],
      [publicProvider()]
    );

    const config = createConfig({
      autoConnect: true,
      publicClient,
      webSocketPublicClient,
    });

    this.watchNetwork = watchNetwork((network) => {
      if (network.chain !== undefined) {
        if (network.chain.id !== polygonMumbai.id) {
          switchNetwork({
            chainId: polygonMumbai.id,
          });
        }
      }
    });

    this.watchWallet = watchWalletClient(
      {
        chainId: polygonMumbai.id,
      },
      (walletClient) => {
        if (walletClient == null || walletClient.account == null) {
          this.walletConnected = false;
        } else {
          this.walletConnected = true;
          this.walletClient = walletClient;
          this.address = this.walletClient.account.address;
          if (this.walletChannel == null && this.address) {
            //create a new wallet channel to listen to:
            this.walletchannel = this.pusher.subscribe(this.address);
            this.walletchannel.bind("update", async function (data) {
              Turbo.renderStreamMessage(data.message);
            });
          }
        }
      }
    );

    this.watchAccount = watchAccount(async (account) => {
      if (account.isConnected) {
        this.walletConnected = true;
        this.walletClient = await getWalletClient();
        console.log("Connected");
        if (this.signedSub == false) {
          this.buttonTarget.innerText = "Subscribe";
          if (this.processedNonce == false) {
            this.getNonce();
          }
        } else {
          this.buttonTarget.innerText = "Approve CoinSub";
        }
      } else {
        console.log("Disconnected");
        this.buttonTarget.innerText = "Connect Wallet";
        this.walletConnected = false;
      }
    });
  }

  loadApprove() {
    this.buttonTarget.innerText = "Approve Coinsub";
  }

  getNonce() {
    console.log("getting nonce");
    console.log(this.walletClient);
    if (this.walletClient && this.walletClient.account) {
      let url = "/subscribe/nonce/" + this.walletClient.account.address;
      fetch(url).then(async (response) => {
        let res = await response.json();
        if (res.hasOwnProperty("nonce")) {
          this.processedNonce = true;
          this.nonce = res.nonce;
        }
      });
    }
  }

  async processInput() {
    if (this.walletConnected == false) {
      const address = await connect({
        connector: new InjectedConnector(),
      });
    } else {
      if (this.signedSub == false && this.walletClient) {
        try {
          let data = this.buildSubscriptionData(
            this.companyValue,
            this.subscriptionValue,
            this.subscriptionidValue,
            this.tokenValue,
            this.toValue,
            this.amountValue,
            this.numberofpaymentsValue,
            this.durationindaysValue,
            this.nonce
          );

          const signature = await this.walletClient.signTypedData({
            domain: data.domain,
            message: data.message,
            primaryType: data.primaryType,
            types: data.types,
          });

          //save to server
          fetch("/sub/signature/", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              signature: signature,
              address: this.walletClient.account.address,
            }),
          }).then(async (response) => {
            this.signedSub = true;
            this.loadApprove();
          });
        } catch (e) {
          console.log(e);
        }
      } else if (this.signedSub && this.permitSigned == false) {
        let nonce = 0;
        let deadline = (Math.floor(Date.now() / 1000) + 600).toString();
        let amount = document.getElementById("approvalrange").dataset.selection;
        if (amount == "Unlimited") {
          amount =
            "115792089237316195423570985008687907853269984665640564039457584007913129639935";
        }

        let data = this.buildPermitData(this.address, amount, nonce, deadline);

        const permitSignature = await this.walletClient.signTypedData({
          domain: data.domain,
          message: data.message,
          primaryType: data.primaryType,
          types: data.types,
        });
        this.permitSigned = true;
        this.processSubscription(permitSignature);
      }
    }
  }

  async processSubscription(permitSignature) {
    const timeout = 1000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    //send permit signature to server
    await fetch("/sub/permit/", {
        method: "POST",
        signal: controller.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          permitSignature: permitSignature,
          address: this.walletClient.account.address,
        }),
      });
    clearTimeout(id);
  }

  connect() {
    //console.log("Hello, Stimulus!", this.element);
  }

  slide({ params }) {
    console.log("event captured");
  }

  buildPermitData(owner, amount, nonce, deadline) {
    //EIP712 Domain Data
    const name = "TERC20P";
    const version = "1";
    const chainId = 80001; //polygon Mumbai
    const verifyingContract = "0xC3E0C66EED19a8dB5958Db7b1Ab8300A55309E01";
    const spenderContract = "0xf3224C3012fABB9A211Bd7E667858c8dD205c586";
    //Subscribe Domain
    const permitDomain = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ];

    return {
      primaryType: "Permit",
      types: { Permit: permitDomain },
      domain: { name, version, chainId, verifyingContract },
      message: {
        owner: owner,
        spender: spenderContract,
        value: amount,
        nonce: nonce,
        deadline: deadline,
      },
    };
  }

  buildSubscriptionData(
    companyName,
    subTitle,
    subscriptionId,
    token,
    to,
    amount,
    numberOfPayments,
    durationInDays,
    nonce
  ) {
    //EIP712 Domain Data
    const name = "CoinSubAgent";
    const version = "1";
    const chainId = 80001; //polygon Mumbai
    const verifyingContract = "0xf3224C3012fABB9A211Bd7E667858c8dD205c586";

    //Subscribe Domain
    const subscribeDomain = [
      { name: "companyName", type: "string" },
      { name: "subTitle", type: "string" },
      { name: "subscriptionId", type: "uint256" },
      { name: "token", type: "string" },
      { name: "to", type: "address" },
      { name: "amount", type: "uint256" },
      { name: "numberOfPayments", type: "string" },
      { name: "durationInDays", type: "uint256" },
      { name: "nonce", type: "uint256" },
    ];

    return {
      primaryType: "Subscribe",
      types: { Subscribe: subscribeDomain },
      domain: { name, version, chainId, verifyingContract },
      message: {
        companyName,
        subTitle,
        subscriptionId,
        token,
        to,
        amount,
        numberOfPayments,
        durationInDays,
        nonce,
      },
    };
  }
}
