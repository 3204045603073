// Import any required libraries or modules
import "../css/styles.css";
import "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";
import Web3Controller from "./controllers/web3_controller";

window.Stimulus = Application.start()
Stimulus.register("web3", Web3Controller);
Stimulus.handleError = (error, message, detail) => {
  console.warn(message, detail)
  ErrorTrackingSystem.captureException(error)
}